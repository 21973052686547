import styled from "styled-components"

const Container = styled.div.attrs({ className: "container" })`
  width: min(68rem, 100%);
  padding: 0 2rem;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: 40rem) {
    padding: 0 1.5rem;
  }
`

export default Container
