import { Text, Button, Link } from "deskie-ui"
import { Link as GatsbyLink } from "gatsby"
import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { Close } from "@styled-icons/material-outlined"
import Cookies from "js-cookie"

const StyleCookieConsent = styled.div`
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  width: min(80%, 32rem);
  padding: 1.5rem;
  background: #ffffff;
  border-radius: 1rem;
  box-shadow: ${props => props.theme.elevation[2]};
  z-index: 99;

  p {
    margin-bottom: 1rem;
  }

  .close {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
  }
`

const CookieConsent = () => {
  const [hasMounted, setHasMounted] = React.useState(false)

  React.useEffect(() => {
    setHasMounted(true)
  }, [])

  const [status, setStatus] = useState<string | undefined>(() =>
    Cookies.get("cookie_consent_status")
  )

  const handleSetConsent = (consent: string) => {
    Cookies.set("cookie_consent_status", consent, { expires: 365 })
    setStatus(consent)
  }

  useEffect(() => {
    if (status) {
      ;(window as any).dataLayer?.push({
        event: `cookie_consent_${status}`,
      })
    }
  }, [status])

  if (status && ["allow", "deny"].includes(status)) {
    return null
  }

  if (!hasMounted) {
    return null
  }

  return (
    <StyleCookieConsent>
      <Text p>
        We gebruiken cookies om uw ervaring te verbeteren en voor
        marketingdoeleinden. Lees meer in onze {/* @ts-ignore */}
        <Link as={GatsbyLink} to="/privacy">
          privacyverklaring
        </Link>
        .
      </Text>
      <Button size="small" onClick={() => handleSetConsent("allow")}>
        Accepteer
      </Button>
      <Button
        onClick={() => handleSetConsent("deny")}
        size="small"
        className="close"
        icon={<Close />}
        appearance="secondary"
      />
    </StyleCookieConsent>
  )
}

export default CookieConsent
