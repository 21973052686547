import React from "react"
import styled from "styled-components"
import Container from "./Container"
import { Link } from "gatsby"

const StyledFooter = styled.div`
  color: white;
  padding: 3rem 0;
  font-weight: 300;
  font-size: 0.875rem;
  line-height: 1.75;

  a {
    color: white;
    transition: 0.1s ease;

    &:hover {
      opacity: 0.8;
    }
  }

  .footer-columns {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(250px, 100%), 1fr));
    gap: 2rem;
    color: white;
  }

  .footer-contact {
    text-align: center;
  }

  .footer-nav nav {
    display: flex;
    flex-direction: column;
  }

  .footer-power {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0 2rem;
    opacity: 0.5;
    margin-top: 2rem;
    font-size: 0.75rem;

    a {
      color: white;
      text-decoration: none;
    }

    img {
      height: 0.75rem;
      margin-left: 0.25rem;
      width: auto;
    }
  }

  .footer-socials {
    display: flex;
    nav {
      margin-left: auto;
    }
    nav > * + * {
      margin-left: 0.5rem;
    }
    svg {
      width: 1.5rem;
      height: auto;
    }
  }

  @media screen and (max-width: 36rem) {
    text-align: center;
  }
`

const Footer: React.FC = () => {
  return (
    <StyledFooter>
      <Container>
        <div className="footer-columns">
          <div className="footer-nav">
            <nav>
              <Link to="/privacy">Privacy Statement</Link>
              <Link to="/terms-and-conditions">Algemene voorwaarden</Link>
              <Link to="/faq">Veelgestelde vragen</Link>
            </nav>
          </div>
          <div className="footer-contact">
            Burgemeester Roelenweg 13K<br />
            8021 EV Zwolle
            <br />
            <a href="tel:0387601066">038 760 1066</a>
            <br />
            <a href="mailto:info@deskie.nl" target="_blank">
              info@deskie.nl
            </a>
          </div>
          <div className="footer-socials">
            <nav>
              <a
                href="https://www.linkedin.com/company/deskie/"
                target="_blank"
                rel="noopener noreferrer"
                title="Deskie op LinkedIn"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fab"
                  data-icon="linkedin"
                  role="img"
                  viewBox="0 0 448 512"
                >
                  <path
                    fill="currentColor"
                    d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"
                  />
                </svg>
              </a>
              <a
                href="https://twitter.com/Deskie_Office"
                target="_blank"
                rel="noopener noreferrer"
                title="Deskie op Twitter"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fab"
                  data-icon="twitter-square"
                  role="img"
                  viewBox="0 0 448 512"
                >
                  <path
                    fill="currentColor"
                    d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm-48.9 158.8c.2 2.8.2 5.7.2 8.5 0 86.7-66 186.6-186.6 186.6-37.2 0-71.7-10.8-100.7-29.4 5.3.6 10.4.8 15.8.8 30.7 0 58.9-10.4 81.4-28-28.8-.6-53-19.5-61.3-45.5 10.1 1.5 19.2 1.5 29.6-1.2-30-6.1-52.5-32.5-52.5-64.4v-.8c8.7 4.9 18.9 7.9 29.6 8.3a65.447 65.447 0 0 1-29.2-54.6c0-12.2 3.2-23.4 8.9-33.1 32.3 39.8 80.8 65.8 135.2 68.6-9.3-44.5 24-80.6 64-80.6 18.9 0 35.9 7.9 47.9 20.7 14.8-2.8 29-8.3 41.6-15.8-4.9 15.2-15.2 28-28.8 36.1 13.2-1.4 26-5.1 37.8-10.2-8.9 13.1-20.1 24.7-32.9 34z"
                  />
                </svg>
              </a>
              <a
                href="https://www.instagram.com/deskie_officeplanner/"
                target="_blank"
                rel="noopener noreferrer"
                title="Deskie op Instagram"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fab"
                  data-icon="instagram-square"
                  role="img"
                  viewBox="0 0 448 512"
                >
                  <path
                    fill="currentColor"
                    d="M224,202.66A53.34,53.34,0,1,0,277.36,256,53.38,53.38,0,0,0,224,202.66Zm124.71-41a54,54,0,0,0-30.41-30.41c-21-8.29-71-6.43-94.3-6.43s-73.25-1.93-94.31,6.43a54,54,0,0,0-30.41,30.41c-8.28,21-6.43,71.05-6.43,94.33S91,329.26,99.32,350.33a54,54,0,0,0,30.41,30.41c21,8.29,71,6.43,94.31,6.43s73.24,1.93,94.3-6.43a54,54,0,0,0,30.41-30.41c8.35-21,6.43-71.05,6.43-94.33S357.1,182.74,348.75,161.67ZM224,338a82,82,0,1,1,82-82A81.9,81.9,0,0,1,224,338Zm85.38-148.3a19.14,19.14,0,1,1,19.13-19.14A19.1,19.1,0,0,1,309.42,189.74ZM400,32H48A48,48,0,0,0,0,80V432a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V80A48,48,0,0,0,400,32ZM382.88,322c-1.29,25.63-7.14,48.34-25.85,67s-41.4,24.63-67,25.85c-26.41,1.49-105.59,1.49-132,0-25.63-1.29-48.26-7.15-67-25.85s-24.63-41.42-25.85-67c-1.49-26.42-1.49-105.61,0-132,1.29-25.63,7.07-48.34,25.85-67s41.47-24.56,67-25.78c26.41-1.49,105.59-1.49,132,0,25.63,1.29,48.33,7.15,67,25.85s24.63,41.42,25.85,67.05C384.37,216.44,384.37,295.56,382.88,322Z"
                  />
                </svg>
              </a>
              <a
                href="https://www.facebook.com/Deskie-Officeplanner-100491808608234"
                target="_blank"
                rel="noopener noreferrer"
                title="Deskie op Facebook"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fab"
                  data-icon="facebook-square"
                  role="img"
                  viewBox="0 0 448 512"
                >
                  <path
                    fill="currentColor"
                    d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z"
                  />
                </svg>
              </a>
            </nav>
          </div>
        </div>
      </Container>
      <div className="footer-power">
        <a target="_blank" href="https://arcady.nl">
          Powered by <img src="/Arcady.png" />
        </a>
        <div>Alle genoemde prijzen zijn exclusief BTW</div>
      </div>
    </StyledFooter>
  )
}

export default Footer
