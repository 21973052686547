import React from "react"
import { PropsWithChildren } from "react"
import { createGlobalStyle } from "styled-components"
import { ThemeProvider } from "deskie-ui"
import { Helmet } from "react-helmet"
import Navbar from "./Navbar"
import CookieConsent from "./CookieConsent"
import Footer from "./Footer"

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${props => props.theme.colors.primary};
  }
  * {
    line-height: inherit;
  }
`

function Layout({
  children,
  pageContext,
  location,
}: PropsWithChildren<{ pageContext: string; location: Location }>) {
  const title =
    "Deskie | Een werkplek reserveren was nog nooit zo makkelijk! | Probeer nu"
  const desc =
    "Reserveer makkelijk en snel jouw werkplek met Deskie. Super simpel, makkelijk en veilig in gebruik."

  return (
    <ThemeProvider>
      <Helmet
        htmlAttributes={{
          lang: "nl",
        }}
      >
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>{title}</title>
        <meta name="description" content={desc} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={desc} />
        <meta name="keywords" content="werkplek, reserveren" />
        <meta
          name="google-site-verification"
          content="google-site-verification=iUBuxpgmJtFQed2gzYQhBejCNC_m5xqlwOBa-P_R4J4"
        />
        {/* <meta property="og:image" content="/some-image.png" /> */}
        <meta property="og:url" content={location.href} />
        <link rel="canonical" href={location.href} />
        <meta property="og:site_name" content="Deskie" />
        {/* <meta name="twitter:card" content="summary_large_image" /> */}
        {/* <meta name="twitter:image:alt" content="image description" /> */}
        <link rel="icon" type="image/svg+xml" href="/favicon.svg" />
        <link rel="apple-touch-icon" href="/icon-192.png" />
      </Helmet>
      <GlobalStyle />
      <Navbar />
      {children}
      <Footer />
      <CookieConsent />
    </ThemeProvider>
  )
}

export default Layout
