import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Logo from "./Logo"
import Container from "./Container"

const StyledNavbar = styled.header`
  position: relative;
  z-index: 999;

  .container {
    height: 6rem;
    align-items: center;
    height: 6rem;
    display: flex;
  }

  .logoLink {
    color: inherit;
    text-decoration: none;
  }

  nav {
    margin-left: auto;

    a {
      border-radius: 16px;
      box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.2);
      background-color: #ffffff;
      color: #6c5dd3;
      font-weight: 600;
      font-size: 1rem;
      text-decoration: none;
      padding: 1rem 2rem;
      transition: 0.1s ease;
      display: inline-flex;

      &:hover {
        background-color: rgba(255, 255, 255, 0.9);
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.3);
      }
    }
  }

  @media screen and (min-width: 68rem) {
    .container {
      width: 100%;
      padding: 0 4rem;
    }
  }
`

function Navbar() {
  return (
    <StyledNavbar>
      <Container>
        <Link className="logoLink" to="/">
          <Logo showName={true} />
        </Link>
        <nav>
          <a href="/login">Inloggen</a>
        </nav>
      </Container>
    </StyledNavbar>
  )
}

export default Navbar
